// utils/copyToClipboard.js
export const copyToClipboard = (text, onCopyCallback) => {
    if (!text) {
      return;
    }
  
    navigator.clipboard
      .writeText(text)
      .then(() => {
        if (onCopyCallback) onCopyCallback(`${text} Copied`);
      })
      .catch((err) => console.error("Failed to copy text to clipboard:", err));
  };
  